<template>
  <el-drawer
    :visible.sync="drawer"
    size="93%"
    custom-class="dataUpdateDialog"
    direction="btt"
    width="80%"
    :before-close="close"
    append-to-body
    v-loading="loading"
    element-loading-text="正在获取中..."
    element-loading-spinner="el-icon-loading"
    destroy-on-close
    stripe
  >
    <template v-slot:title>
      <div>数据导入</div>
    </template>
    <div class="drawer_content">
      <div class="flexBet" style="margin-bottom: 12px">
        <div class="flexBet">
          <el-button @click="promotionTemplateExport">下载模板</el-button>
          <el-button @click="downloadErrorData">下载错误原因</el-button>
        </div>
        <div>
          <el-upload
            class="buttonunActive"
            style="display: inline-block"
            action="#"
            :before-upload="beforeUpload"
            :on-change="onChangeFile"
          >
            <el-button class="buttonActive">上传</el-button>
          </el-upload>
          <el-button
            @click="confirm"
            type="primary"
            v-loading.fullscreen.lock="fullscreenLoading"
            >确认入库</el-button
          >
        </div>
      </div>
      <PocTable
        ref="dataUpdateDialogTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :page-size.sync="pageConfig.pageSize"
        size="mini"
        stripe
        :max-height="100000000"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <!-- <el-table-column label="序号" type="index" width="60"></el-table-column> -->
        <el-table-column
          prop="csId"
          label="CS ID"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="englishName"
          label="English Name"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="jobTitleEN"
          label="New Job Title EN"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="jobTitleCN"
          label="New Job Title CN"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="jobGrade"
          label="New Job Grade"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="noticePeriod"
          label="New Notice Period"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="annualBaseSalary"
          label="New Annual BaseSalary"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="thirteenSalary"
          label="New 13th"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="benefits"
          label="Benefits(CNY)"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="annualHousingAllowance"
          label="Annual Housing Allowance"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="annualLivingAllowance"
          label="Annual Living Allowance"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="annualEducationAllowance"
          label="Annual Education Allowance"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="annualOtherAllowance"
          label="Annual Other Allowance"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="relocationAllowance"
          label="Relocation Allowance"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="targetBonus"
          label="Target Bonus %"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="effectiveDate"
          label="Effective Date"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="hrbpOwner"
          label="HRBP Owner"
          min-width="150"
        ></el-table-column>
        <el-table-column
          :label="errorLabel"
          key="checkMessage"
          align="left"
          min-width="350"
        >
          <template v-slot="scope">
            <div style="color: #de3934">
              {{ scope.row['checkMessage'] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" fixed="right" align="center">
          <template v-slot="scope">
            <svg-icon
              icon="delete"
              class="tablePointSvgClass"
              title="删除"
              @click.native="deImportInfo(scope.row)"
            />
          </template>
        </el-table-column>
      </PocTable>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'dataUpdateDialog',
  mixins: [$PCommon.TableMixin],
  data() {
    return {
      drawer: false,
      loading: false,
      tableData: [],
      pageConfig: {},
      errorCount: 0,
      fullscreenLoading: false
    }
  },
  computed: {
    errorLabel() {
      return `错误原因（${this.errorCount}）`
    }
  },
  methods: {
    show() {
      this.drawer = true
      // this.pageConfig.pageNum = 1
      // this.queryApi()
      // this.csErrorCount()
      this.pageConfig.pageSize = 10
      this.pageConfig.total = 0
    },
    close() {
      this.$confirm(
        '当前数据尚未入库，关闭页面，数据将丢失，是否继续？',
        '提示',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }
      )
        .then(() => {
          this.tableData = []
          this.errorCount = 0
          this.$Api.candidate.deAllImportInfo()
          this.drawer = false
        })
        .catch((action) => {})
    },
    downloadErrorData() {
      if (this.errorCount === 0) {
        this.$message.error('没有错误数据')
        return
      }
      this.$Api.candidate.staffPromotionExportWithCheckMessage(this.groupId)
    },
    promotionTemplateExport() {
      this.$Api.candidate.promotionTemplateExport()
    },
    confirm() {
      if (this.errorCount > 0) {
        this.$message.error('请先处理错误数据后入库')
        return
      }
      if (this.tableData.length === 0) {
        this.$message.error('请先上传数据')
        return
      }
      this.fullscreenLoading = true
      this.$Api.candidate
        .staffPromotionExcelActive({ groupId: this.groupId })
        .then(({ code, msg }) => {
          this.fullscreenLoading = false
          if (code === 200) {
            this.$message.success(msg)
            setTimeout(() => {
              this.tableData = []
              this.errorCount = 0
              this.$Api.candidate.deAllImportInfo()
              this.drawer = false
            }, 800)
          }
        })
    },
    csErrorCount() {
      this.$Api.candidate.csErrorCount().then(({ code, data }) => {
        if (code === 200) {
          this.errorCount = data
        }
      })
    },
    deImportInfo(row) {
      this.$confirm('是否删除这条数据？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$Api.candidate
            .staffPromotionExcelDeleteRow({ id: row.id })
            .then(({ code }) => {
              if (code === 200) {
                this.$message.success('删除成功')
                this.tableData = this.tableData.filter(
                  (item) => item.id !== row.id
                )
                // 如果删除的当前页的最后一条，则自动请求前一页
                if (this.tableData.length == 0) {
                  this.pageConfig.pageNum--
                  if (this.pageConfig.pageNum) {
                    this.queryApi()
                  }
                }
                this.pageConfig.total -= 1
                // 错误条数 -1
                this.errorCount--
              }
            })
        })
        .catch(() => {})
    },
    /**
     * 上传 已经填写数据的 模版 前 校验
     * @param {*} file
     */
    onChangeFile(file) {
      if (file.status !== 'ready') return
      if (file.name.endsWith('.xlsx')) {
        this.upload(file)
      } else {
        this.$message.error('只能上传xlsx格式的文件')
      }
    },
    /**
     * 阻止文件上传自动请求post
     */
    beforeUpload() {
      return false
    },
    /**
     * 上传 已经填写数据的 模版
     * @param {*} file
     */

    upload(file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      let loadingInstance = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$Api.candidate
        .staffPromotionExcelImport(formData)
        .then(({ code, data, msg }) => {
          loadingInstance.close()
          if (code === 200) {
            this.$message({
              type: 'success',
              message: msg
            })
            // 获取倒入结果 分页数据
            this.groupId = data
            this.queryApi()
          }
        })
    },
    /**
     * 批量晋升 倒入之后 结果加载 分页
     */
    queryApi() {
      this.loading = true
      this.$Api.candidate
        .staffPromotionExcelPpage({ ...this.pageConfig, groupId: this.groupId })
        .then(({ code, data }) => {
          this.loading = false
          if (code === 200) {
            this.tableData = data.page.records || []
            this.pageConfig.total = data.page.total
            this.errorCount = data.errorCount
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.dataUpdateDialog {
  .el-drawer__header {
    div {
      &:first-child {
        display: inline-block;
        padding-left: 100px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
      u {
        margin-left: 20px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .el-drawer__body {
    .drawer_content {
      position: relative;
      width: 100%;
      height: calc(100vh - 60px - 160px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 120px 0 120px;
    }
  }
  .el-drawer__close-btn {
    margin-right: 100px;
  }
  .delBtn {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
</style>
<style>
.el-drawer {
  border-radius: 16px 16px 0 0 !important;
}
</style>
