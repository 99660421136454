<template>
  <div class="employees">
    <div class="employees__topHeader">
      <div class="employees__topHeader__leftTitle">员工列表</div>
      <div class="employees__topHeader__middleTab">
        <el-radio-group v-model="pageTab" @change="pageTabChange" size="small">
          <el-radio-button label="onTheJob"
            >在职 ({{
              Store.getters.staffInfoCount.onTheJobCount
            }})</el-radio-button
          >
          <el-radio-button label="toBeEmployed"
            >待入职 ({{
              Store.getters.staffInfoCount.toBeEmployedCount
            }})</el-radio-button
          >
          <el-radio-button label="resign"
            >已离职 ({{
              Store.getters.staffInfoCount.resignCount
            }})</el-radio-button
          >
          <el-radio-button label="changeManagement"
            >异动管理 ({{
              Store.getters.staffInfoCount.changeManagementCount
            }})</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="flexBet" style="width: 400px;display: flex;justify-content: end;">
        <el-button @click="reset" class="resetButton" v-show="pageTab !== 'changeManagement'">
          <i class="el-icon-refresh-left buttonrefreshSpan"></i>
        </el-button>
        <el-button @click="dataUpdateSelectType" v-permission role="csDataUpdate" v-show="pageTab === 'onTheJob'">数据更新</el-button>
        <!-- <el-button
          role="downloadStaffList"
          v-permission
          @click="DownloadStaffList"
          size="mini"
          v-show="pageTab !== 'changeManagement'"
        >
          下载全部Staff List
        </el-button> -->
        <el-dropdown
          role="downloadStaffList"
          v-permission
          split-button
          class="download_staff_list"
          v-show="pageTab !== 'changeManagement'"
          @click="DownloadStaffList"
          @command="handleCommand"
        >
          下载全部Staff List
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item role="downloadChildrenInfo" v-permission command="downloadChildrenInfoList">
                下载子女信息
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary" @click="search" v-show="pageTab !== 'changeManagement'">搜索</el-button>
      </div>
    </div>
    <div class="employees__listForm">
      <employeesListForm v-if="pageTab !== 'changeManagement'" :pageTab="pageTab" />
    </div>
    <div class="employees__container">
      <component ref="pagesRef" :is="dynamicalComponent[pageTab]" />
    </div>
    <dataUpdateDialog ref="dataUpdateDialogRef"/>
    <dataUpdateDialogForPromotion ref="dataUpdateDialogForPromotionRef" />
    <el-dialog
      title="数据更新"
      :visible.sync="dataUpdateSelectDialogVisible"
      width="30%"
    >
      <div class="dataUpdateRadio">
        <span>请选择您要更新的内容</span>
        <el-radio class="dataUpdateRadio_item" v-model="dataUpdateType" label="CSID_LLID_BusinessEmail">CS ID / LLID / Business Email</el-radio>
        <el-radio v-model="dataUpdateType" label="Promotion">批量晋升</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dataUpdateSelectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataUpdateTypeSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { removeItem } from '@/utils/storage'

export default {
  beforeRouteLeave: function (to, from, next) {
    let path = ['/employees/hired', '/employees/employeesItemEdit']
    if (!path.includes(to.path)) {
      // 离开员工列表路由 删除所有本地缓存的数据
      removeItem('pageTab__yc')
      removeItem('changeManagement__yc')
      removeItem('ChangeManagementTabs__yc')
      removeItem('saveClickRow__yc')
      removeItem('staffInfoCount__yc')
      removeItem('tabsClickData__yc')
    }
    next()
  }
}
</script>
<script setup>
import { ref, reactive, Ref, onMounted, provide } from "vue";
import onTheJob from './pages/onTheJob.vue'
import toBeEmployed from './pages/toBeEmployed.vue'
import resign from './pages/resign.vue'
import changeManagement from './pages/changeManagement.vue'
import employeesListForm from "./components/employeesListForm.vue";
import { getItem, setItem } from '@/utils/storage'
import { useRefs, useStore } from "@/utils/webApi";
import { exportStaffList, exportStaffChildrenList } from "@/api/employees";
import dataUpdateDialog from "@/views/employee/dialog/dataUpdateDialog";
import dataUpdateDialogForPromotion from "@/views/employee/dialog/dataUpdateForPromotionDialog";
const Store = useStore()
const $refs = useRefs()
//页面标签名字
let pageTab = ref('onTheJob')
let dynamicalComponent = reactive({
  onTheJob,
  toBeEmployed,
  resign,
  changeManagement
})
// 批量更新数据 选择框 选择
let dataUpdateSelectDialogVisible = ref(false)
let dataUpdateType = ref('')

const getPageTab = () => {
  let pageTab__yc = getItem('pageTab__yc')
  if (pageTab__yc) {
    pageTab.value = pageTab__yc
  }
}
getPageTab()
const pageTabChange = () => {
  setItem('pageTab__yc', pageTab.value)
  reset()
}

const pagesRef = ref(null)
const search = () => {
  if (pagesRef) {
    pagesRef.value.search()
  }
}
const formInline = reactive({data:{
    hfmCode:'',
    level1:'',
    level2:'',
    level3:'',
    levelIds_1:[],
    levelIds_2:[],
    levelIds_3:[],
    joinDateStart:'',
    joinDateEnd:'',
    process:'',
    chineseName:'',
    englishName:'',
    lastEmploymentDate:'',
    lastWorkingDate:'',
    lastEmploymentDateEnd:'',
    lastEmploymentDateStart:'',
    lastWorkingDateEnd:'',
    lastWorkingDateStart:'',
    contractDueDateEnd:'',
    contractDueDateStart:'',
    contractDueDate:'',
    notify:'',
    orderColumn:'',
    orderAsc:'',
}})
const reset = ()=> {
  formInline.data = {
    hfmCode:'',
    level1:'',
    level2:'',
    level3:'',
    levelIds_1:[],
    levelIds_2:[],
    levelIds_3:[],
    joinDateStart:'',
    joinDateEnd:'',
    process:'',
    chineseName:'',
    englishName:'',
    lastEmploymentDateEnd:'',
    lastEmploymentDateStart:'',
    lastWorkingDateEnd:'',
    lastWorkingDateStart:'',
    contractDueDateEnd:'',
    contractDueDateStart:'',
    notify:'',
    orderColumn:'',
    orderAsc:'',
  }
  search()
}
provide('formInline',formInline)
//下载全部Staff List
const DownloadStaffList = () => {
  exportStaffList()
}
/**
 * 下载按钮多选项
 */
const handleCommand = (command) => {
  
  switch (command) {
    case 'downloadChildrenInfoList':
        // 下载子女信息
        exportStaffChildrenList()
      break;
  
    default:
      break;
  }
}
/**
 * 更新数据前选择更新的数据的类型
 */
const dataUpdateSelectType = () => {
  // 显示弹窗
  dataUpdateSelectDialogVisible.value = true
}
/**
 * 更新数据 弹窗 类型 确认
 */
const dataUpdateTypeSubmit = () => {
  if (!dataUpdateType.value) {
    this.$message.error('请选择您要更新的内容')
  }

  // 关闭弹窗
  dataUpdateSelectDialogVisible.value = false

  switch (dataUpdateType.value) {
    case 'CSID_LLID_BusinessEmail':
      dataUpdate()
      break;
    case 'Promotion':
      $refs.dataUpdateDialogForPromotionRef.show()
      break;
  
    default:
      break;
  }
}

const dataUpdate = () => {
  $refs.dataUpdateDialogRef.show()
}
</script>

<style lang="scss" scoped>
.employees {
  &__topHeader {
    width: 100%;
    height: 69px;
    background: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    &__leftTitle {
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #303133;
    }
    &__middleTab {
      :deep(.el-radio-group) {
        .el-radio-button {
          .el-radio-button__inner {
            width: 100px;
            height: 30px;
          }
        }
      }
    }
  }
  &__listForm{
    background: #ffffff;
    padding: 0 20px;
  }
  &__container {
    margin-top: 15px;
    box-sizing: border-box;
    position: relative;
    background: #ffffff;
  }

  .dataUpdateRadio {
    .dataUpdateRadio_item {
      display: block;
      margin: 15px 0;
    }
  }
}
</style>
<style lang="scss">
.download_staff_list {
  margin-right: 12px;
  .el-button-group {
    .el-button {
      margin: 0px !important
    }
  }
}
</style>
