export const onTheJobColumn = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'level2',
    name: 'Level 2',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'level3',
    name: 'Level 3',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'location',
    name: 'Location /（City）',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 160 // 默认宽度，像素
  },
  {
    key: 'hrbpOwner',
    name: 'HRBP Owner',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'joinDate',
    name: 'Join Date',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: 'custom', // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 140 // 默认宽度，像素
  },
  // {
  //   key: 'noticeItAndAdmin',
  //   name: '是否已通知IT/ADMIN',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: 180 // 默认宽度，像素
  // },
  // {
  //   key: 'hrbpOwner',
  //   name: 'HRBP Owner',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: 120 // 默认宽度，像素
  // },
  // {
  //   key: 'employmentConfirmationDate',
  //   name: '转正日期',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: 120 // 默认宽度，像素
  // },
  // {
  //   key: 'renewalDate',
  //   name: '续签日期',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: 120 // 默认宽度，像素
  // },
  // {
  //   key: 'flow',
  //   name: '流程',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: null // 默认宽度，像素
  // },
  // {
  //   key: 'status',
  //   name: '状态',
  //   dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
  //   visible: true, // 是否展示在表格列中
  //   sortable: false, // 是否可以排序
  //   fixed: null, // 固定列的位置(left, right)
  //   width: 200 // 默认宽度，像素
  // }
]
