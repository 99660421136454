<template>
  <PocSlider minheight="70px" btntype="primary">
    <el-form
      :inline="true"
      :model="formInline"
      label-position="top"
      class="el-form elformInputBox minWidthInput"
    >
      <el-form-item label="HFM Code">
        <el-select
          v-model="formInline.data.hfmCode"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in hfmCodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 1">
        <el-select
          v-model="formInline.data.level1"
          clearable
          filterable
          placeholder="请选择"
          @change="levelChange"
          @clear="formInline.data.levelIds_1 = []"
        >
          <el-option
            v-for="item in levelList1"
            :key="item.id"
            :label="item.orgName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 2">
        <el-select
          v-model="formInline.data.level2"
          clearable
          filterable
          placeholder="请选择"
          @change="levelChange"
          @clear="formInline.data.levelIds_2 = []"
        >
          <el-option
            v-for="item in levelList2"
            :key="item.id"
            :label="item.orgName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 3">
        <el-select
          v-model="formInline.data.level3"
          clearable
          filterable
          placeholder="请选择"
          @change="levelChange"
          @clear="formInline.data.levelIds_3 = []"
        >
          <el-option
            v-for="item in levelList3"
            :key="item.id"
            :label="item.orgName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Join Date" v-if="pageTab !=='resign'">
        <el-date-picker
          v-model="formInline.data.joinDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="joinDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Contract Due Date">
        <el-date-picker
          v-model="formInline.data.contractDueDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="contractDueDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="流程" v-if="pageTab === 'onTheJob'">
        <el-select
          v-model="formInline.data.process"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in processList"
            :key="item.code"
            :label="item.desc"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="English Name">
        <el-input
          placeholder="请输入"
          v-model="formInline.data.englishName"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Chinese Name">
        <el-input
          placeholder="请输入"
          v-model="formInline.data.chineseName"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Last Employment Date" v-if="pageTab !== 'toBeEmployed'">
        <el-date-picker
          v-model="formInline.data.lastEmploymentDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="lastEmploymentDateChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Last Working Date" v-if="pageTab !== 'toBeEmployed'">
        <el-date-picker
          v-model="formInline.data.lastWorkingDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="lastWorkingDateChange"
        >
        </el-date-picker>
      </el-form-item>
        <el-form-item label="是否通知IT/Admin" v-if="pageTab === 'toBeEmployed'">
          <el-select
            v-model="formInline.data.notify"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in [{k:true,v:'是'},{k:false,v:'否'}]"
              :key="item.k"
              :label="item.v"
              :value="item.k"
            >
            </el-option>
          </el-select>
        </el-form-item>
    </el-form>
  </PocSlider>
</template>

<script setup>
import { ref, reactive, onMounted, computed, defineProps, toRefs, inject } from "vue";
import { getHfmCodeList } from '@/api/organization'
import { getOrgSelection } from "@/api/org";
import { getStaffFlow } from '@/api/staff'

const props = defineProps({
  pageTab:String
})
const {pageTab} = toRefs(props)
const formInline = inject('formInline')
const hfmCodeList = ref([])
const levelList = ref([])
const processList = ref([])
const getFormInlineData = () =>{
  getHfmCodeList().then(({code,data})=>{
    if(code === 200){
      hfmCodeList.value = data
    }
  })
  getOrgSelection().then(({code,data})=>{
    if(code === 200){
      levelList.value = data
    }
  })
  getStaffFlow().then(({code,data})=>{
    if(code === 200){
      processList.value = data
    }
  })
}
const levelList1 = computed(()=>{
  return levelList.value.filter(le=>le.depth === 2)
})
const levelList2 = computed(()=>{
  if(formInline.data.level1){
    return levelList.value.filter(le=>le.orgParentId === formInline.data.level1)
  }
  return levelList.value.filter(le=>le.depth === 3)
})
const levelList3 = computed(()=>{
  if(formInline.data.level2){
    return levelList.value.filter(le=>le.orgParentId === formInline.data.level2)
  }
  return levelList.value.filter(le=>le.depth === 4)
})
const levelChange = (val)=> {
  if(val){
    let value = levelList.value.filter(el=>el.id === val)[0]
    if(value.depth === 2){
      formInline.data.level2 = ''
      formInline.data.level3 = ''
      // let levelids2 = levelList.value.filter(el=>el.orgParentId === val).map(el=>{return el.id})
      // formInline.data.levelIds_1 = levelList.value.filter(el=>levelids2.includes(el.orgParentId)).map(el=>{return el.id})
      formInline.data.levelIds_1 = [val]
      formInline.data.levelIds_2 = []
      formInline.data.levelIds_3 = []
    }else if(value.depth === 3){
      formInline.data.level3 = ''
      // formInline.data.levelIds_2 = levelList.value.filter(el=>el.orgParentId === val).map(el=>{return el.id})
      formInline.data.levelIds_2 = [val]
      formInline.data.levelIds_3 = []
    }else {
      formInline.data.levelIds_3 = [val]
    }
  }else {
    formInline.data.levelIds_3 = []
  }
}
const joinDateChange = (val) =>{
  if (val) {
    formInline.data.joinDateStart = val[0]
    formInline.data.joinDateEnd = val[1]
  } else {
    formInline.data.joinDateStart = ''
    formInline.data.joinDateEnd = ''
  }
}
const contractDueDateChange = (val) =>{
  if (val) {
    formInline.data.contractDueDateStart = val[0]
    formInline.data.contractDueDateEnd = val[1]
  } else {
    formInline.data.contractDueDateStart = ''
    formInline.data.contractDueDateEnd = ''
  }
}
const lastEmploymentDateChange = (val) =>{
  if (val) {
    formInline.data.lastEmploymentDateStart = val[0]
    formInline.data.lastEmploymentDateEnd = val[1]
  } else {
    formInline.data.lastEmploymentDateStart = ''
    formInline.data.lastEmploymentDateEnd = ''
  }
}
const lastWorkingDateChange = (val) =>{
  if (val) {
    formInline.data.lastWorkingDateStart = val[0]
    formInline.data.lastWorkingDateEnd = val[1]
  } else {
    formInline.data.lastWorkingDateStart = ''
    formInline.data.lastWorkingDateEnd = ''
  }
}
onMounted(()=>{
  getFormInlineData()
})
defineExpose({
  formInline
})
</script>

<style lang="scss" scoped>
::v-deep .poc-slider-btns{
  //padding-top: 28px;
}

</style>
