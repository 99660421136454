import request from '@/utils/request'
import store from '@/store'

export const getTree = (data) => {
  return request({
    url: '/sysOrg/getTree',
    params: {
      ...data
    }
  })
}

export const getOrg = (data) => {
  return request({
    url: '/sysOrg/get',
    params: {
      ...data
    }
  })
}
export const getHistoryLog = (data) => {
  return request({
    url: '/sysOrg/getHistoryLog',
    params: {
      ...data
    }
  })
}
export const EditSave = (data) => {
  return request({
    url: '/sysOrg/save',
    method: 'POST',
    data
  })
}
export const getAllUser = (data) => {
  return request({
    url: '/sysOrg/getAllUser'
  })
}
const url = process.env['VUE_APP_BASE_API']
export const sysOrgExport = ({ isDataRight, status }) => {
  window.location = `${url}/api/iris/sysOrg/export?isDataRight=${isDataRight}&status=${status}&token=${store.getters.token}`
}

export const getOperateLog = (data) => {
  return request({
    url: '/sysOrg/getOperateLog',
    params: {
      ...data
    }
  })
}
export const getOrgSelection = (data) => {
  return request({
    url: '/sysOrg/tree/selection',
    method: 'POST',
    data
  })
}
