import { getEmployeesList, getEmployeesListInfo } from "@/api/employees";
import { getItem } from '@/utils/storage'
import Store from "@/store";
export const getEmployeesApi = (queryData, targetData, PocTableRef) => {
  const data = {
    ...queryData
  }
  targetData.pageConfig.loading = true
  let tabsClickData__yc = getItem('tabsClickData__yc')
  getEmployeesList(data).then((res) => {
    targetData.pageConfig.loading = false
    if (res && res.code === 200) {
      Store.dispatch('employee/getStaffInfoCount')
      targetData.tableData = res.data.list || []
      targetData.pageConfig.total = res.data.total
      if (tabsClickData__yc) {
        let val = targetData.tableData.find(
          (item) =>
            item.candidateInformationId ===
            tabsClickData__yc.candidateInformationId
        )
        if (val) {
          PocTableRef.setCurrentRow(val)
        }
      }
    }
  })
}

export const getEmployeesApiInfo = (queryData, targetData, PocTableRef) => {
  const data = {
    ...queryData
  }
  targetData.pageConfig.loading = true
  let tabsClickData__yc = getItem('tabsClickData__yc')
  getEmployeesListInfo(data).then((res) => {
    targetData.pageConfig.loading = false
    if (res && res.code === 200) {
      Store.dispatch('employee/getStaffInfoCount')
      targetData.tableData = res.data.list || []
      targetData.pageConfig.total = res.data.total
      if (tabsClickData__yc) {
        let val = targetData.tableData.find(
          (item) =>
            item.candidateInformationId ===
            tabsClickData__yc.candidateInformationId
        )
        if (val) {
          PocTableRef.setCurrentRow(val)
        }
      }
    }
  })
}
