<template>
  <div class="changeManagement">
    <div class="changeManagement__tabs">
      <el-tabs
        v-model="changeManagementData.changeManagementType"
        @tab-click="handleClick"
      >
        <el-tab-pane label="所有" :name="changeManagementTabs.ALL">
          <span slot="label">所有 {{ Store.getters.staffInfoCount.changeManagementCount }}</span>
        </el-tab-pane>
        <el-tab-pane label="调岗" :name="changeManagementTabs.postTransfer">
          <span slot="label"
            >调岗 {{ Store.getters.staffInfoCount.changeManagementTransferCount }}</span
          >
        </el-tab-pane>
        <el-tab-pane label="晋升" :name="changeManagementTabs.promotion">
          <span slot="label"
            >晋升 {{ Store.getters.staffInfoCount.changeManagementPromotionCount }}</span
          >
        </el-tab-pane>
        <el-tab-pane label="离职" :name="changeManagementTabs.quit">
          <span slot="label"
            >离职 {{ Store.getters.staffInfoCount.changeManagementResignCount }}</span
          >
        </el-tab-pane>
        <el-tab-pane
          label="转正"
          :name="changeManagementTabs.becomeRegularWorker"
        >
          <span slot="label"
            >转正 {{ Store.getters.staffInfoCount.changeManagementPositiveCount }}</span
          >
        </el-tab-pane>
        <el-tab-pane label="续签" :name="changeManagementTabs.renewal">
          <span slot="label"
            >续签 {{ Store.getters.staffInfoCount.changeManagementRenewCount }}</span
          >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="changeManagement__searchBar">
      <div style="display: flex">
        <el-dropdown trigger="click" @command="commandChange">
          <span class="changeManagement__searchBar__dropdownText">
            <svg-icon icon="screen" />
            {{ dropdownStatusText
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="dropdownConst.agency.value">{{
              dropdownConst.agency.cn
            }}</el-dropdown-item>
            <el-dropdown-item
              divided
              :command="dropdownConst.haveInHand.value"
              >{{ dropdownConst.haveInHand.cn }}</el-dropdown-item
            >
            <el-dropdown-item :command="dropdownConst.Closed.value">{{
              dropdownConst.Closed.cn
            }}</el-dropdown-item>
            <el-dropdown-item :command="dropdownConst.processes.value">{{
              dropdownConst.processes.cn
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div style="margin-left: 12px">
          <el-button
            @click="downloadPerson"
            v-if="
              changeManagementData.changeManagementType ===
              changeManagementTabs.becomeRegularWorker
            "
            >下载员工评估表（{{ selectLength }}）</el-button
          >
        </div>
      </div>

      <div class="changeManagement__searchBar__searchInput">
        <el-input
          v-model="changeManagementData.keyWord"
          class="changeManagement__searchBar__searchInput__item"
          suffix-icon="el-icon-search"
          placeholder="输入名字、HFM Code"
        ></el-input>
        <el-button type="primary" @click="search"> 查询 </el-button>
        <el-button
          role="downloadChangeTableList"
          v-permission
          @click="downloadTableList"
          size="mini"
          v-loading.fullscreen.lock="fullscreenLoading"
        >导出</el-button
        >
      </div>
    </div>
    <div class="changeManagement__table">
      <component
        :is="dynamicalTable"
        ref="changeManagementTableRef"
        @handleSelectionChange="handleSelectionChange"
      />
    </div>
  </div>
</template>

<script setup>
import becomeRegularWorkerTable from '@/views/employee/pages/components/becomeRegularWorkerTable'
import postTransferTable from '@/views/employee/pages/components/postTransferTable'
import promotionTable from '@/views/employee/pages/components/promotionTable'
import allTable from '@/views/employee/pages/components/allTable'
import renewTable from '@/views/employee/pages/components/renewTable'
import quitTable from '@/views/employee/pages/components/quitTable'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRefs,useStore } from '@/utils/webApi'
import { getItem, removeItem, setItem } from '@/utils/storage'
import { downloadPositiveResult,downLoadChangeList } from '@/api/employees'
import { downLoadFileByBlob } from "@/utils/util";
const Store = useStore()
const $refs = useRefs()
const changeManagementTabs = reactive({
  //所有
  ALL: 'all',
  //调岗
  postTransfer: 'Transfer',
  //晋升
  promotion: 'Promotion',
  //离职
  quit: 'Resign',
  //转正
  becomeRegularWorker: 'Positive',
  //续签
  renewal: 'Renew'
})

const HFMList = ref([])

const dropdownConst = reactive({
  agency: {
    cn: '我的待办',
    value: 'myToDo'
  },
  haveInHand: {
    cn: '进行中',
    value: '0'
  },
  Closed: {
    cn: '已结束',
    value: '1'
  },
  processes: {
    cn: '全部流程',
    value: 'all'
  }
})

// const countStaff = reactive({
//   changeManagementCount: 0,
//   changeManagementTransferCount: 0,
//   changeManagementPromotionCount: 0,
//   changeManagementResignCount: 0,
//   changeManagementPositiveCount: 0,
//   changeManagementRenewCount: 0
// })
const changeManagementData = reactive({
  changeManagementType: changeManagementTabs.ALL,
  type: 'changeManagement',
  keyWord: '',
  myType: dropdownConst.agency.value
})
const getChangeManagementType = () => {
  let ChangeManagementTabs__yc = getItem('ChangeManagementTabs__yc')
  if (ChangeManagementTabs__yc) {
    changeManagementData.changeManagementType = ChangeManagementTabs__yc
  }
}
getChangeManagementType()
const getChangeManagementTableRefListData = () => {
  console.log("bbb---",changeManagementData)
  setItem('changeManagement__yc', changeManagementData)
  setTimeout(() => {

    $refs.changeManagementTableRef.queryApi(changeManagementData)
  }, 0)
}
const dropdownStatusText = ref(dropdownConst.agency.cn)
const commandChange = (val) => {
  Object.keys(dropdownConst).forEach((item) => {
    if (dropdownConst[item].value === val) {
      dropdownStatusText.value = dropdownConst[item].cn
      changeManagementData.myType = dropdownConst[item].value
    }
  })
  getChangeManagementTableRefListData()
}
const dyComponent = reactive({
  [changeManagementTabs.ALL]: allTable,
  [changeManagementTabs.renewal]: renewTable,
  [changeManagementTabs.promotion]: promotionTable,
  [changeManagementTabs.quit]: quitTable,
  [changeManagementTabs.postTransfer]: postTransferTable,
  [changeManagementTabs.becomeRegularWorker]: becomeRegularWorkerTable
})
const saveChangeManagementTabs = () => {
  setItem('ChangeManagementTabs__yc', changeManagementData.changeManagementType)
}

let dynamicalTable = ref(dyComponent[changeManagementTabs.ALL])
const handleClick = () => {
  dynamicalTable.value = dyComponent[changeManagementData.changeManagementType]
  console.log("dynamicalTable--",dynamicalTable.value)
  //移除上一个tabs的信息
  removeItem('changeManagement__yc')
  // 移除列表回显数据
  removeItem('saveClickRow__yc')
  //保存当前tabs,刷新
  saveChangeManagementTabs()
  // 获取表格数据
  getChangeManagementTableRefListData()
}
// const setCount = () => {
//   let staffInfoCount = getItem('staffInfoCount__yc')
//   if (staffInfoCount) {
//     Object.keys(countStaff).forEach((item) => {
//       countStaff[item] = staffInfoCount[item]
//     })
//   }
// }
const selectLength = ref(0)
const selectVal = ref([])
const handleSelectionChange = (val) => {
  if (val) {
    selectVal.value = val
    selectLength.value = val.length
  }
}
const downloadPerson = () => {
  if (selectLength.value !== 0) {
    downloadPositiveResult({
      ids: selectVal.value.map((item) => item.candidateInformationId).toString()
    })
  }
}
const fullscreenLoading = ref(false)
const downloadTableList = () =>{
  getChangeManagementType()
  const data = {
    changeType:changeManagementData.changeManagementType
  }
  fullscreenLoading.value = true
  downLoadChangeList(data).then(res=>{
    fullscreenLoading.value = false
    if(res){
      downLoadFileByBlob(res)
    }
  })
}
onMounted(() => {
  handleClick()
  // setCount()
})
const search = () => {
  $refs.changeManagementTableRef.pageConfig.pageNum = 1;
  getChangeManagementTableRefListData()
}
</script>

<style lang="scss" scoped>
.changeManagement {
  &__tabs {
    box-sizing: border-box;
    padding: 16px 20px 0 20px;
  }
  &__searchBar {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 2px 20px 8px 20px;
    &__searchInput {
      .el-input,
      .el-select {
        width: 200px;
        height: 30px;
      }
      &__item {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__dropdownText {
      color: $--color-button-primary;
      cursor: pointer;
      display: inline-block;
      margin-top: 7px;
    }
  }
  &__table {
    box-sizing: border-box;
    padding: 0 20px 20px 20px;
  }
}
</style>
